import { useAccount, useDisconnect, useEnsName } from 'wagmi';
import classnames from 'classnames';

import { useIsMounted } from '../../utils/hooks';

import styles from '../../sass/components/auth/ConnectButton.module.scss';

export function ConnectButton(props) {
  const { address, isConnected } = useAccount();

  const isMounted = useIsMounted();

  const { data: ensName } = useEnsName({ address });

  const { disconnect } = useDisconnect({
    onError(error) {
    },
    onSuccess(data) {
      if (props.onDisconnect) {
        props.onDisconnect();
      }
    },
  });

  const addrs = () => {
    const addr = address.slice(0, 5) + '...' + address.slice(-4);
    return ensName ? ensName : addr;
  }

  function handleConnectClick() {
    if (props.onConnect) {
      props.onConnect();
    }
  }

  if (isMounted && isConnected) {
    return (
      <button
        className={classnames({
          [styles.connectButton]: true,
          [styles.authorized]: true,
          [styles.accented]: props.accented,
          [styles.withoutIcon]: props.withoutIcon,
        })}
        onClick={disconnect}
        >
        { addrs() }
      </button>
    );
  }

  if (isMounted) {
    return (
      <button
        className={classnames({
          [styles.connectButton]: true,
          [styles.authorized]: false,
          [styles.accented]: props.accented,
          [styles.withoutIcon]: props.withoutIcon,
        })}
        onClick={handleConnectClick}
      >
        {props.text || 'Connect'}
      </button>
    )
  }
}