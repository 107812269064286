import React, { useEffect } from 'react';
import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import { useAccount, useSignMessage, useDisconnect } from 'wagmi';

import { ConnectButton } from './ConnectButton';
import { ConnectProviders } from './ConnectProviders';
import { sendRequest, triggerEvent } from '../../utils/global';
import { loginAction, logoutAction } from '../../utils/auth';
import Storage from '../../utils/Storage';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
    isMobile: store.setup?.isMobile,
  };
};

export function ConnectWallet(props) {

  const { address, connector, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  // check wether we have a user or not, disconnect if not
  useEffect(() => {
    const accessToken = localStorage.getItem('pnthn-client:access_token') || null;
    if (!accessToken) disconnect();
  }, []);

  const disconnectUser = async () => {
    await logoutAction(); // removes tokens, destroys sessions
    if (props.onDisconnect) {
      props.onDisconnect();
    } else {
      props.router.push('/');
    }
  }

  const loadUser = (silent = false) => {
    if (!silent) triggerEvent('updateLoadText', [{ text: 'Retrieving user data...' }]);
    sendRequest({
      type: 'GET',
      method: 'users/me',
      silent: true,
    }).then(async (user) => {
      triggerEvent('hideContentPopup');
      Storage.setData('user', user);
      requestUnreadEndorsements();
      // if (!silent) triggerEvent('removeLoad');
      const wizardStep = await checkProfileIsFilled(user, silent);
      typeof window !== 'undefined' && localStorage.removeItem('pnthn-client:isGenesis');
      if (props.onConnect && !silent) {
        props.onConnect(user, wizardStep);
      }
    }).catch(error => {
      if (error.error === 'Forbidden' && props.refreshToken) {
        refreshAccessToken(props.refreshToken);
      } else {
        disconnectUser();
      }
    });
  }

  const checkProfileIsFilled = async (user, silent) => {
    if (!silent) triggerEvent('updateLoadText', [{ text: 'Validating profile' }]);

    // const experience = await loadExperience(user._id);
    if (!user.name || !user.username || !user.images || user.images.length === 0) {
      if (!silent) triggerEvent('removeAllLoads');
      // triggerEvent('showSnackbar', [{text: 'Please, complete your profile', type: 'error'}]);
      // props.router.push('/wizard/step/1');
      Storage.setData('profileCompleted', false);
      Storage.setData('wizardStep', '/wizard/step/1');
      return '/wizard/step/1';
    }
    if (!user.twitter || !user.discord) {
      if (!silent) triggerEvent('removeAllLoads');
      // triggerEvent('showSnackbar', [{text: 'Please, complete your profile', type: 'error'}]);
      // props.router.push('/wizard/step/2');
      Storage.setData('profileCompleted', false);
      Storage.setData('wizardStep', '/wizard/step/2');
      return '/wizard/step/2';
    }
    if (!user.summary || !user.bio) {
      if (!silent) triggerEvent('removeAllLoads');
      // triggerEvent('showSnackbar', [{text: 'Please, complete your profile', type: 'error'}]);
      // props.router.push('/wizard/step/3');
      Storage.setData('profileCompleted', false);
      Storage.setData('wizardStep', '/wizard/step/3');
      return '/wizard/step/3';
    }
    if (!user.roles || user.roles.length === 0) {
      if (!silent) triggerEvent('removeAllLoads');
      // triggerEvent('showSnackbar', [{text: 'Please, complete your profile', type: 'error'}]);
      // props.router.push('/wizard/step/4');
      Storage.setData('profileCompleted', false);
      Storage.setData('wizardStep', '/wizard/step/4');
      return '/wizard/step/4';
    }
    /*
    if (experience && experience.length === 0) {
      if (!silent) triggerEvent('removeAllLoads');
      // triggerEvent('showSnackbar', [{text: 'Please, complete your profile', type: 'error'}]);
      // props.router.push('/wizard/step/5');
      Storage.setData('profileCompleted', false);
      Storage.setData('wizardStep', '/wizard/step/5');
      return false;
    }
    */

    Storage.setData('profileCompleted', true);
    Storage.setData('wizardStep', null);
    triggerEvent('removeAllLoads');

    return null;
  }

  /*
  const loadExperience = async (id) => {
    try {
      const experience = await sendRequest({
        method: `experience?user_id=${id}`,
        type: 'GET',
        silent: true,
      });
      return experience;
    } catch (error) {
      console.log('error', error);
    }
  };
  */

  const requestUnreadEndorsements = () => {
    sendRequest({
      type: 'GET',
      method: 'endorsements/unread',
      silent: true,
    }).then(result => {
      Storage.setData('unreadEndorsements', result);
    }).catch(error => {
      console.log('error', error);
    });
  }

  const refreshAccessToken = (refreshToken) => {
    sendRequest({
      type: 'POST',
      method: `sessions/refresh/${refreshToken}`,
      silent: true,
    }).then(result => {
      loadUser(false);
    }).catch(error => {
      triggerEvent('removeAllLoads');
      disconnectUser();
    });
  }

  const handleLogin = (account, connectorName) => {
    triggerEvent('addLoad', [{ text: `Launching ${connectorName}` }]);
    sendRequest({
      type: 'GET',
      method: 'sessions/nonce',
      data: {
        address: account
      },
      silent: true,
    }).then(result => {
      if (!result) {
        addUser(account, connectorName);
      } else {
        createSignature(result, account, connectorName);
      }
    }).catch(error => {
      triggerEvent('removeAllLoads');
    });
  }

  const addUser = (account, connectorName) => {
    sendRequest({
      type: 'POST',
      method: 'users',
      data: {
        address: account
      },
      silent: true,
    }).then(result => {
      typeof window !== 'undefined' && localStorage.removeItem('pnthn-client:isGenesis');
      createSignature(result.nonce, account, connectorName);
    }).catch(error => {
      triggerEvent('removeAllLoads');
    });
  }

  const { signMessageAsync } = useSignMessage();

  const createSignature = async (message, address, connectorName) => {
    triggerEvent('updateLoadText', [{ text: `Awaiting ${connectorName} confirmation` }]);
    try {
      const signature = await signMessageAsync({
        message,
      });
      openSession(signature, address);
    } catch (error) {
      triggerEvent('removeAllLoads');
      disconnect();
      triggerEvent('showSnackbar', [{text: `Error ${error.code}: ${error.message}`, type: 'error'}]);
    }
  }

  const openSession = (signature, account) => {
    triggerEvent('updateLoadText', [{ text: 'Authorizing...' }]);
    sendRequest({
      type: 'POST',
      method: 'sessions',
      data: {
        address: account,
        signature
      },
      silent: true,
    }).then(async (data) => {
      await loginAction(data);
      loadUser(false);
    }).catch(error => {
      // attempt to fix signature & login for hardwallets
      retryOpenSession(fixSignatureV(signature), account);
    });
  }

  const fixSignatureV = (signature) => {
    if (signature.slice(-2) === '00') {
      return signature.slice(0, -2) + '1b';
    }
    if (signature.slice(-2) === '01') {
      return signature.slice(0, -2) + '1c';
    }
    return signature;
  }

  const retryOpenSession = (signature, account) => {
    sendRequest({
      type: 'POST',
      method: 'sessions',
      data: {
        address: account,
        signature
      },
      silent: true,
    }).then(async (data) => {
      await loginAction(data);
      triggerEvent('removeLoad');
      loadUser(false);
    }).catch(error => {
      triggerEvent('removeAllLoads');
    });
  }

  function handleSelectProvider(data) {
    triggerEvent('hideContentPopup');
    handleLogin(data.account, data.connector.name);
  }

  const handleConnectClick = () => {
    triggerEvent('showContentPopup', [{
      title: 'Select Wallet',
      content: <ConnectProviders onConnect={handleSelectProvider} />,
    }]);
  }

  return (
    <ConnectButton withoutIcon={props.buttonWithoutIcon} text={props.buttonText} onConnect={handleConnectClick} onDisconnect={disconnectUser} />
  );
}

export default connect(mapStoreToProps)(withRouter(ConnectWallet));