import { useConnect } from 'wagmi';
import classnames from 'classnames';

import { useIsMounted } from '../../utils/hooks';
import { triggerEvent } from '../../utils/global';

import styles from '../../sass/components/auth/ConnectProviders.module.scss';

export function ConnectProviders(props) {
  const isMounted = useIsMounted();
  const { connect, connectors, isLoading, pendingConnector } =
    useConnect({
      onError(error) {
        triggerEvent('showSnackbar', [{text: `Error ${error.code}: ${error.message}`, type: 'error'}]);
      },
      onSuccess(data) {
        if (props.onConnect) {
          props.onConnect(data);
        }
      },
    });

  return (
    <div>
      {connectors
        .filter((x) => isMounted && x.ready)
        .map((x) => (
          <button
            className={classnames(styles.connectProvider, {
              [styles[x.id]]: true
            })}
            key={x.id}
            onClick={() => connect({ connector: x })}
          >
            {x.name}
            {isLoading && x.id === pendingConnector?.id && ' (connecting)'}
          </button>
        ))}
    </div>
  );
}